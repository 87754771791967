<template>
    <v-row class="ma-0">
        <v-col cols="12" lg="6" xl="6" class="primary d-none d-md-flex align-center justify-center">
            <v-container>
                <div class="pa-0">
                    
                    <v-row justify="center">
                        <img src="/img/gerb3.png" style="opacity:0.5;"/>
                    </v-row>
                </div>
            </v-container>
        </v-col>
        <v-col cols="12" lg="6" xl="6" class="d-flex align-center">
            <v-container>
                <div class="pa-7 pa-sm-12">
                    <img src="/gerb.png" id="logo" style="height: 62px; margin-top:10px;"/><h1 class="font-weight-thin blue--text text--lighten-2" style="font-weight: 900 !important;font-size: 60px; display: inline; margin-top: 5px;">ЭИОС МЧС РОССИИ</h1>
                    <v-row>
                        <v-col cols="12" lg="9" xl="6">
                            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Восстановить пароль</h2>
                            <h6 class="subtitle-1">
                                Нет аккаунта?
                                <router-link to="/authentication/register">Зарегистрируйтесь!</router-link>
                            </h6>
                            <v-form ref="form" v-model="valid" action="#" @keyup.enter="login()" v-on:submit.prevent="login()" lazy-validation>
                                <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    label="E-mail"
                                    placeholder="E-mail"
                                    class="mt-4"
                                    required
                                    

                                    ></v-text-field>
                                <v-btn
                                    :disabled="!valid"
                                    color="info"
                                    block
                                    class="mr-4"
                                    submit
                                    v-on:click="reset_by_email()"
                                    >Восстановить</v-btn>
                            </v-form>
                            <div class="text-center mt-6" v-if="1!==1">
                                <v-chip pill class="mr-2">
                                    <v-avatar left>
                                        <v-btn color="blue lighten-1" class="white--text">
                                            <v-icon>mdi-twitter</v-icon>
                                        </v-btn>
                                    </v-avatar>Twitter
                                </v-chip>
                                <v-chip pill>
                                    <v-avatar left>
                                        <v-btn color="teal lighten-2" class="white--text">
                                            <v-icon>mdi-github</v-icon>
                                        </v-btn>
                                    </v-avatar>Github
                                </v-chip>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "FullLogin",
        data: () => ({
                email: "",
                emailRules: [
                    v => !!v || "E-mail должен быть заполнен",
                    v => /.+@.+\..+/.test(v) || "E-mail введен неверно"
                ],
                checkbox: false,
                logging: false,
                error: null
            }),
        methods: {
            reset_by_email() {
                this.$http.get('user/reset-password', {
                    params: {
                        email: this.email
                    }
                })
                        .then(response => {
                            alert(response.data)
                            this.$router.push('/authentication/login')
                        }).catch(e => {
                    alert(e.response.data)
                })
            },
        }
    };
</script>
